// ** React Imports
import { Fragment, SyntheticEvent, useState } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** MUI Imports
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

// ** Sentry
import * as Sentry from '@sentry/nextjs'

// ** Icons Imports
import { googleLogout } from '@react-oauth/google'
import Cookies from 'js-cookie'
import LogoutVariant from 'mdi-material-ui/LogoutVariant'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { useTranslation } from 'next-i18next'
import { User } from 'src/store/api/coreApi'

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

// const styles = {
//   py: 2,
//   px: 4,
//   width: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   color: 'text.primary',
//   textDecoration: 'none',
//   '& svg': {
//     fontSize: '1.375rem',
//     color: 'text.secondary'
//   }
// }

interface IMDUserDropdown {
  userInfo: User
  onUnmaskToken: any
}

const UserDropdown = ({ userInfo, onUnmaskToken }: IMDUserDropdown) => {
  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const { t } = useTranslation()

  // ** Hooks
  const router = useRouter()

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }

  const onLogout = () => {
    try {
      Cookies.remove('userToken')
      localStorage.removeItem('userToken')
      if (userInfo.email?.includes('moodus.com')) {
        googleLogout()
      }
      onUnmaskToken()
      handleDropdownClose('/login')
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
    }
  }

  return (
    <Fragment>
      <Badge
        overlap='circular'
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: 'pointer' }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Avatar
          alt='John Doe'
          onClick={handleDropdownOpen}
          sx={{ width: 40, height: 40 }}
          src={
            userInfo?.client
              ? `/images/avatars/${Math.floor(Math.random() * 5) + 1}.png`
              : '/images/apple-touch-icon.png'
          }
        />
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, marginTop: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <Badge
              overlap='circular'
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Avatar
                alt='John Doe'
                src={userInfo?.client ? '/images/apple-touch-icon.png' : '/images/apple-touch-icon.png'}
                sx={{ width: '2.5rem', height: '2.5rem' }}
              />
            </Badge> */}
            <AccountCircleOutlinedIcon sx={{ fontSize: '1.375rem', color: 'text.secondary' }} />
            <Box sx={{ display: 'flex', marginLeft: 3, alignItems: 'flex-start', flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: 600 }}>{`${userInfo?.first_name} ${userInfo?.last_name}`}</Typography>
              <Typography variant='body2' sx={{ fontSize: '0.8rem', color: 'text.disabled' }}>
                {userInfo?.client ? (userInfo?.client as any)?.name : t('MoodusStaff')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: 0, mb: 1 }} />
        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <AccountOutline sx={{ marginRight: 2 }} />
            Profile
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <EmailOutline sx={{ marginRight: 2 }} />
            Inbox
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <MessageOutline sx={{ marginRight: 2 }} />
            Chat
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <CogOutline sx={{ marginRight: 2 }} />
            Settings
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <CurrencyUsd sx={{ marginRight: 2 }} />
            Pricing
          </Box>
        </MenuItem> */}
        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <HelpCircleOutline sx={{ marginRight: 2 }} />
            Mask Client
          </Box>
        </MenuItem>
        <Divider /> */}
        <MenuItem
          sx={{ py: 2 }}
          onClick={() => {
            window.open('https://drive.google.com/file/d/12xu8cQd3h6FygNu58pogvKkkYZlF2P_Y/view?usp=sharing', '_blank')
          }}
        >
          <DescriptionOutlinedIcon sx={{ marginRight: 2, fontSize: '1.375rem', color: 'text.secondary' }} />
          {t('Manual')}
        </MenuItem>
        <Divider />
        <MenuItem sx={{ py: 2 }} onClick={() => onLogout()}>
          <LogoutVariant sx={{ marginRight: 2, fontSize: '1.375rem', color: 'text.secondary' }} />
          {t('Logout')}
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default UserDropdown
