import { Typography } from '@mui/material'
import type { Middleware } from '@reduxjs/toolkit'
import { isRejectedWithValue } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = () => next => action => {
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 400) {
      for (const [key, value] of Object.entries(action.payload.data)) {
        toast(() => (
          <span>
            <Typography variant='button'>
              <b>{key}</b>
            </Typography>
            {/* {(value as string[]).map(e => (
              <p key={e}>{e}</p>
            ))} */}
            {Array.isArray(value) 
              ? value.map(e => <p key={e}>{e}</p>) 
              : <p>{value}</p>
            }
          </span>
        ))
      }
    }
  }

  return next(action)
}
