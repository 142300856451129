/* eslint-disable */

import { Qa } from 'react-flags-select'

import { api } from './api'

export const addTagTypes = ['core', 'give', 'give_plus', 'google', 'password_reset'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      coreClientsList: build.query<CoreClientsListApiResponse, CoreClientsListApiArg>({
        query: queryArg => ({
          url: `/core/clients/`,
          params: {
            q: queryArg.q,
            page: queryArg.page,
            page_size: queryArg.pageSize,
            ordering: queryArg.sortBy,

            name__icontains: queryArg.nameIcontains,
            name__iexact: queryArg.nameEquals,
            name__istartswith: queryArg.nameStartsWith,
            name__iendswith: queryArg.nameEndsWith,
            name__isnull: queryArg.nameIsEmpty,
            name__in: queryArg.nameIsAnyOf,

            wefact_client_id__icontains: queryArg.wefactClientIdIcontains,
            wefact_client_id__iexact: queryArg.wefactClientIdEquals,
            wefact_client_id__istartswith: queryArg.wefactClientIdStartsWith,
            wefact_client_id__iendswith: queryArg.wefactClientIdEndsWith,
            wefact_client_id__isnull: queryArg.wefactClientIdIsEmpty,
            wefact_client_id__in: queryArg.wefactClientIdIsAnyOf,


            is_active: queryArg.is_active
          }
        }),
        providesTags: ['core']
      }),
      coreClientsCreate: build.mutation<CoreClientsCreateApiResponse, CoreClientsCreateApiArg>({
        query: queryArg => ({ url: `/core/clients/`, method: 'POST', body: queryArg.client }),
        invalidatesTags: ['core']
      }),
      coreClientsRead: build.query<CoreClientsReadApiResponse, CoreClientsReadApiArg>({
        query: queryArg => ({ url: `/core/clients/${queryArg.id}/` }),
        providesTags: ['core']
      }),
      coreClientsUpdate: build.mutation<CoreClientsUpdateApiResponse, CoreClientsUpdateApiArg>({
        query: queryArg => ({ url: `/core/clients/${queryArg.id}/`, method: 'PUT', body: queryArg.client }),
        invalidatesTags: ['core']
      }),
      coreClientsPartialUpdate: build.mutation<CoreClientsPartialUpdateApiResponse, CoreClientsPartialUpdateApiArg>({
        query: queryArg => ({ url: `/core/clients/${queryArg.id}/`, method: 'PUT', body: queryArg.client }),
        invalidatesTags: ['core']
      }),
      coreClientsDelete: build.mutation<CoreClientsDeleteApiResponse, CoreClientsDeleteApiArg>({
        query: queryArg => ({ url: `/core/clients/${queryArg.id}/`, method: 'DELETE' }),
        invalidatesTags: ['core']
      }),
      coreUploadsList: build.query<CoreUploadsListApiResponse, CoreUploadsListApiArg>({
        query: queryArg => ({ url: `/core/uploads/`, params: { limit: queryArg.limit, offset: queryArg.offset } }),
        providesTags: ['core']
      }),
      coreUploadsCreate: build.mutation<CoreUploadsCreateApiResponse, CoreUploadsCreateApiArg>({
        query: queryArg => ({ url: `/core/uploads/`, method: 'POST', body: queryArg.imageUploader }),
        invalidatesTags: ['core']
      }),
      coreUploadsRead: build.query<CoreUploadsReadApiResponse, CoreUploadsReadApiArg>({
        query: queryArg => ({ url: `/core/uploads/${queryArg.id}/` }),
        providesTags: ['core']
      }),
      coreUploadsUpdate: build.mutation<CoreUploadsUpdateApiResponse, CoreUploadsUpdateApiArg>({
        query: queryArg => ({ url: `/core/uploads/${queryArg.id}/`, method: 'PUT', body: queryArg.imageUploader }),
        invalidatesTags: ['core']
      }),
      coreUploadsPartialUpdate: build.mutation<CoreUploadsPartialUpdateApiResponse, CoreUploadsPartialUpdateApiArg>({
        query: queryArg => ({ url: `/core/uploads/${queryArg.id}/`, method: 'PATCH', body: queryArg.imageUploader }),
        invalidatesTags: ['core']
      }),
      coreUploadsDelete: build.mutation<CoreUploadsDeleteApiResponse, CoreUploadsDeleteApiArg>({
        query: queryArg => ({ url: `/core/uploads/${queryArg.id}/`, method: 'DELETE' }),
        invalidatesTags: ['core']
      }),
      coreUsersList: build.query<CoreUsersListApiResponse, CoreUsersListApiArg>({
        query: queryArg => ({
          url: `/core/users/`,
          params: {
            client: queryArg.client,
            q: queryArg.q,
            page: queryArg.page,
            page_size: queryArg.pageSize,
            ordering: queryArg.sortBy,

            username__icontains: queryArg.usernameIcontains,
            username__iexact: queryArg.usernameEquals,
            username__istartswith: queryArg.usernameStartsWith,
            username__iendswith: queryArg.usernameEndsWith,
            username__isnull: queryArg.usernameIsEmpty,
            username__in: queryArg.usernameIsAnyOf,

            first_name__icontains: queryArg.firstNameIcontains,
            first_name__iexact: queryArg.firstNameEquals,
            first_name__istartswith: queryArg.firstNameStartsWith,
            first_name__iendswith: queryArg.firstNameEndsWith,
            first_name__isnull: queryArg.firstNameIsEmpty,
            first_name__in: queryArg.firstNameIsAnyOf,

            last_name__icontains: queryArg.lastNameIcontains,
            last_name__iexact: queryArg.lastNameEquals,
            last_name__istartswith: queryArg.lastNameStartsWith,
            last_name__iendswith: queryArg.lastNameEndsWith,
            last_name__isnull: queryArg.lastNameIsEmpty,
            last_name__in: queryArg.lastNameIsAnyOf,

            email__icontains: queryArg.emailIcontains,
            email__iexact: queryArg.emailEquals,
            email__istartswith: queryArg.emailStartsWith,
            email__iendswith: queryArg.emailEndsWith,
            email__isnull: queryArg.emailIsEmpty,
            email__in: queryArg.emailIsAnyOf,

            client__name__icontains: queryArg.clientIcontains,
            client__name__iexact: queryArg.clientEquals,
            client__name__istartswith: queryArg.clientStartsWith,
            client__name__iendswith: queryArg.clientEndsWith,
            client__name__isnull: queryArg.clientIsEmpty,
            client__name__in: queryArg.clientIsAnyOf,

            is_active: queryArg.is_active,

            is_staff: queryArg.is_staff,

            last_login__date: queryArg.lastLoginIs,
            last_login_is_not: queryArg.lastLoginNot,
            last_login__gt: queryArg.lastLoginAfter,
            last_login_gte: queryArg.lastLoginOnOrAfter,
            last_login__lt: queryArg.lastLoginBefore,
            last_login_lte: queryArg.lastLoginOnOrBefore,
            last_login__isnull: queryArg.lastLoginIsEmpty
          }
        }),
        providesTags: ['core']
      }),
      coreUsersCreate: build.mutation<CoreUsersCreateApiResponse, CoreUsersCreateApiArg>({
        query: queryArg => ({ url: `/core/users/`, method: 'POST', body: queryArg.user }),
        invalidatesTags: ['core']
      }),
      coreUsersChangePassword: build.mutation<CoreUsersChangePasswordApiResponse, CoreUsersChangePasswordApiArg>({
        query: queryArg => ({ url: `/core/users/change_password/`, method: 'POST', body: queryArg.changePassword }),
        invalidatesTags: ['core']
      }),
      coreUsersGetAllPermissions: build.query<CoreUsersGetAllPermissionsApiResponse, CoreUsersGetAllPermissionsApiArg>({
        query: queryArg => ({
          url: `/core/users/get_all_permissions/`,
          params: {
            email: queryArg.email,
            email__icontains: queryArg.emailIcontains,
            last_name: queryArg.lastName,
            last_name__icontains: queryArg.lastNameIcontains,
            first_name: queryArg.firstName,
            first_name__icontains: queryArg.firstNameIcontains,
            username: queryArg.username,
            username__icontains: queryArg.usernameIcontains,
            client: queryArg.client,
            q: queryArg.q,
            page: queryArg.page,
            page_size: queryArg.pageSize
          }
        }),
        providesTags: ['core']
      }),
      coreUsersGetClients: build.query<CoreUsersGetClientsApiResponse, CoreUsersGetClientsApiArg>({
        query: queryArg => ({
          url: `/core/users/get_clients/`,
          params: {
            email: queryArg.email,
            email__icontains: queryArg.emailIcontains,
            last_name: queryArg.lastName,
            last_name__icontains: queryArg.lastNameIcontains,
            first_name: queryArg.firstName,
            first_name__icontains: queryArg.firstNameIcontains,
            username: queryArg.username,
            username__icontains: queryArg.usernameIcontains,
            client: queryArg.client,
            q: queryArg.q,
            page: queryArg.page,
            page_size: queryArg.pageSize
          }
        }),
        providesTags: ['core']
      }),
      coreUsersGetUserInfo: build.query<CoreUsersGetUserInfoApiResponse, CoreUsersGetUserInfoApiArg>({
        query: queryArg => ({
          url: `/core/users/get_user_info/`,
          params: {
            email: queryArg.email,
            email__icontains: queryArg.emailIcontains,
            last_name: queryArg.lastName,
            last_name__icontains: queryArg.lastNameIcontains,
            first_name: queryArg.firstName,
            first_name__icontains: queryArg.firstNameIcontains,
            username: queryArg.username,
            username__icontains: queryArg.usernameIcontains,
            client: queryArg.client,
            q: queryArg.q,
            page: queryArg.page,
            page_size: queryArg.pageSize
          }
        }),
        providesTags: ['core']
      }),
      coreUsersMaskClient: build.mutation<CoreUsersMaskClientApiResponse, CoreUsersMaskClientApiArg>({
        query: queryArg => ({ url: `/core/users/mask_client/`, method: 'POST', body: queryArg.maskUserRequest }),
        invalidatesTags: ['core']
      }),
      coreUsersRead: build.query<CoreUsersReadApiResponse, CoreUsersReadApiArg>({
        query: queryArg => ({ url: `/core/users/${queryArg.id}/` }),
        providesTags: ['core']
      }),
      coreUsersUpdate: build.mutation<CoreUsersUpdateApiResponse, CoreUsersUpdateApiArg>({
        query: queryArg => ({ url: `/core/users/${queryArg.id}/`, method: 'PUT', body: queryArg.user }),
        invalidatesTags: ['core']
      }),
      coreUsersPartialUpdate: build.mutation<CoreUsersPartialUpdateApiResponse, CoreUsersPartialUpdateApiArg>({
        query: queryArg => ({ url: `/core/users/${queryArg.id}/`, method: 'PATCH', body: queryArg.user }),
        invalidatesTags: ['core']
      }),
      coreUsersDelete: build.mutation<CoreUsersDeleteApiResponse, CoreUsersDeleteApiArg>({
        query: queryArg => ({ url: `/core/users/${queryArg.id}/`, method: 'DELETE' }),
        invalidatesTags: ['core']
      }),
      giveTerminalsList: build.query<GiveTerminalsListApiResponse, GiveTerminalsListApiArg>({
        query: queryArg => ({
          url: `/give/terminals/`,
          params: {
            client: queryArg.client,
            q: queryArg.q,
            page: queryArg.page,
            page_size: queryArg.pageSize,
            ordering: queryArg.sortBy,

            serial_number__icontains: queryArg.serialNumberIcontains,
            serial_number__iexact: queryArg.serialNumberEquals,
            serial_number__istartswith: queryArg.serialNumberStartsWith,
            serial_number__iendswith: queryArg.serialNumberEndsWith,
            serial_number__isnull: queryArg.serialNumberIsEmpty,
            serial_number__in: queryArg.serialNumberIsAnyOf,
            serial_number: queryArg.serialNumber,

            name__icontains: queryArg.nameIcontains,
            name__iexact: queryArg.nameEquals,
            name__istartswith: queryArg.nameStartsWith,
            name__iendswith: queryArg.nameEndsWith,
            name__isnull: queryArg.nameIsEmpty,
            name__in: queryArg.nameIsAnyOf,
            name: queryArg.nameContains,

            offline: queryArg.offline,

            is_enabled: queryArg.is_enabled
          }
        }),
        providesTags: ['give']
      }),
      giveTerminalsCreate: build.mutation<GiveTerminalsCreateApiResponse, GiveTerminalsCreateApiArg>({
        query: queryArg => ({ url: `/give/terminals/`, method: 'POST', body: queryArg.giveTerminal }),
        invalidatesTags: ['give']
      }),
      giveTerminalsRead: build.query<GiveTerminalsReadApiResponse, GiveTerminalsReadApiArg>({
        // query: queryArg => ({ url: `/give/terminals/${queryArg.id}/` }),
        query: queryArg => ({ url: `/give/terminals-details/${queryArg.id}/` }),
        providesTags: ['give']
      }),
      giveTerminalsUpdate: build.mutation<GiveTerminalsUpdateApiResponse, GiveTerminalsUpdateApiArg>({
        query: queryArg => ({ url: `/give/terminals-details/${queryArg.id}/`, method: 'PUT', body: queryArg.giveTerminal }),
        invalidatesTags: ['give']
      }),
      giveTransactionsList: build.query<GiveTransactionsListApiResponse, GiveTransactionsListApiArg>({
        query: queryArg => ({
          url: `/give/transactions/`,
          params: {
            date: queryArg.date,
            clients: queryArg.clients,
            terminals: queryArg.terminals,
            q: queryArg.q,
            client_id: queryArg.client_id,
            page: queryArg.page,
            page_size: queryArg.pageSize,
            ordering: queryArg.sortBy,

            terminal__name__icontains: queryArg.terminalIcontains,
            terminal__name__iexact: queryArg.terminalEquals,
            terminal__name__istartswith: queryArg.terminalStartsWith,
            terminal__name__iendswith: queryArg.terminalEndsWith,
            terminal__name__isnull: queryArg.terminalIsEmpty,
            terminal__name__in: queryArg.terminalIsAnyOf,

            credit_card_brand__icontains: queryArg.creditCardBrandIcontains,
            credit_card_brand__iexact: queryArg.creditCardBrandEquals,
            credit_card_brand__istartswith: queryArg.creditCardBrandStartsWith,
            credit_card_brand__iendswith: queryArg.creditCardBrandEndsWith,
            credit_card_brand__isnull: queryArg.creditCardBrandIsEmpty,
            credit_card_brand__in: queryArg.creditCardBrandIsAnyOf,

            client__name__icontains: queryArg.clientIcontains,
            client__name__iexact: queryArg.clientEquals,
            client__name__istartswith: queryArg.clientStartsWith,
            client__name__iendswith: queryArg.clientEndsWith,
            client__name__isnull: queryArg.clientIsEmpty,
            client__name__in: queryArg.clientIsAnyOf,

            donate: queryArg.donate,
            donate_ne: queryArg.donate__ne,
            donate__gt: queryArg.donate__gt,
            donate__gte: queryArg.donate__gte,
            donate__lt: queryArg.donate__lt,
            donate__lte: queryArg.donate__lte,
            donate__isnull: queryArg.donateIsEmpty,
            donate__in: queryArg.donateIsAnyOf,

            datetime__date: queryArg.datetimeIs,
            datetime_is_not: queryArg.datetimeNot,
            datetime__gt: queryArg.datetimeAfter,
            datetime_gte: queryArg.datetimeOnOrAfter,
            datetime__lt: queryArg.datetimeBefore,
            datetime_lte: queryArg.datetimeOnOrBefore,
            datetime__isnull: queryArg.datetimeIsEmpty
          }
        }),
        providesTags: ['give']
      }),
      giveTransactionsGetChartsTransactionsStatistics: build.query<
        GiveTransactionsGetChartsTransactionsStatisticsApiResponse,
        GiveTransactionsGetChartsTransactionsStatisticsApiArg
      >({
        query: queryArg => ({
          url: `/give/transactions/get_charts_transactions_statistics/`,
          params: {
            date: queryArg.date,
            clients: queryArg.clients,
            terminals: queryArg.terminals,
            q: queryArg.q,
            page: queryArg.page,
            page_size: queryArg.pageSize
          }
        }),
        providesTags: ['give']
      }),
      giveTransactionsGetChartsTransactionsStatisticsByTerminals: build.query<
        GiveTransactionsGetChartsTransactionsStatisticsByTerminalsApiResponse,
        GiveTransactionsGetChartsTransactionsStatisticsByTerminalsApiArg
      >({
        query: queryArg => ({
          url: `/give/transactions/get_charts_transactions_statistics_by_terminals/`,
          params: {
            date: queryArg.date,
            clients: queryArg.clients,
            client_id: queryArg.client_id,
            terminals: queryArg.terminals,
            q: queryArg.q,
            page: queryArg.page,
            page_size: queryArg.pageSize,

            // date_after: queryArg.dateAfter, 

            // date_before: queryArg.dateBefore,
            datetime__gt: queryArg.dateAfter,
            datetime_lte: queryArg.dateBefore,
            report_type: queryArg.reportType
          }
        }),
        providesTags: ['give']
      }),
      giveTransactionsGetTransactionsStatistics: build.query<
        GiveTransactionsGetTransactionsStatisticsApiResponse,
        GiveTransactionsGetTransactionsStatisticsApiArg
      >({
        query: queryArg => ({
          url: `/give/transactions/get_transactions_statistics/`,
          params: {
            date: queryArg.date,
            clients: queryArg.clients,
            client_id: queryArg.client_id,
            terminals: queryArg.terminals,
            q: queryArg.q,
            page: queryArg.page,
            page_size: queryArg.pageSize,
            date_after: queryArg.dateAfter,
            date_before: queryArg.dateBefore,
            report_type: queryArg.reportType
          }
        }),
        providesTags: ['give']
      }),
      giveTransactionsRead: build.query<GiveTransactionsReadApiResponse, GiveTransactionsReadApiArg>({
        query: queryArg => ({ url: `/give/transactions/${queryArg.id}/` }),
        providesTags: ['give']
      }),
      givePlusPosTerminalsCreate: build.mutation<
        GivePlusPosTerminalsCreateApiResponse,
        GivePlusPosTerminalsCreateApiArg
      >({
        query: queryArg => ({ url: `/give_plus/pos_terminals/`, method: 'POST', body: queryArg.posTerminal }),
        invalidatesTags: ['give_plus']
      }),
      givePlusPosTerminalsSendReceiptCreate: build.mutation<
        GivePlusPosTerminalsSendReceiptCreateApiResponse,
        GivePlusPosTerminalsSendReceiptCreateApiArg
      >({
        query: queryArg => ({
          url: `/give_plus/pos_terminals/send_receipt/`,
          method: 'POST',
          body: queryArg.posTerminalReceipt
        }),
        invalidatesTags: ['give_plus']
      }),
      givePlusPosTerminalsRead: build.query<GivePlusPosTerminalsReadApiResponse, GivePlusPosTerminalsReadApiArg>({
        query: queryArg => ({ url: `/give_plus/pos_terminals/${queryArg.id}/` }),
        providesTags: ['give_plus']
      }),
      givePlusPosTransactionsCreate: build.mutation<
        GivePlusPosTransactionsCreateApiResponse,
        GivePlusPosTransactionsCreateApiArg
      >({
        query: queryArg => ({
          url: `/give_plus/pos_transactions/`,
          method: 'POST',
          body: queryArg.givePlusTransaction
        }),
        invalidatesTags: ['give_plus']
      }),
      givePlusProjectsList: build.query<GivePlusProjectsListApiResponse, GivePlusProjectsListApiArg>({
        query: queryArg => ({
          url: `/give_plus/projects/`,
          params: { title: queryArg.title, title__icontains: queryArg.titleIcontains }
        }),
        providesTags: ['give_plus']
      }),
      givePlusProjectsRead: build.query<GivePlusProjectsReadApiResponse, GivePlusProjectsReadApiArg>({
        query: queryArg => ({ url: `/give_plus/projects/${queryArg.id}/` }),
        providesTags: ['give_plus']
      }),
      givePlusTerminalsList: build.query<GivePlusTerminalsListApiResponse, GivePlusTerminalsListApiArg>({
        query: queryArg => ({
          // url: `/give_plus/terminals/`,
          url: `/give_plus/terminals-searchfilter/`,
          params: {
            serial_number__icontains: queryArg.serialNumberIcontains,
            serial_number: queryArg.serialNumber || queryArg.serialNumberEquals,
            serial_number__istartswith: queryArg.serialNumberStartsWith,
            serial_number__iendswith: queryArg.serialNumberEndsWith,
            serial_number__isnull: queryArg.serialNumberIsEmpty,
            serial_number__in: queryArg.serialNumberIsAnyOf,

            terminal_serial_number__icontains: queryArg.terminalSerialNumberIcontains,
            terminal_serial_number: queryArg.terminalSerialNumberEquals,
            terminal_serial_number__istartswith: queryArg.terminalSerialNumberStartsWith,
            terminal_serial_number__iendswith: queryArg.terminalSerialNumberEndsWith,
            terminal_serial_number__isnull: queryArg.terminalSerialNumberIsEmpty,
            terminal_serial_number__in: queryArg.terminalSerialNumberIsAnyOf,

            name__icontains: queryArg.nameIcontains,
            name: queryArg.nameEquals,
            name__istartswith: queryArg.nameStartsWith,
            name__iendswith: queryArg.nameEndsWith,
            name__isnull: queryArg.nameIsEmpty,
            name__in: queryArg.nameIsAnyOf,

            created_at__date: queryArg.createdAtIs,
            created_at_is_not: queryArg.createdAtNot,
            created_at__gt: queryArg.createdAtAfter,
            created_at_gte: queryArg.createdAtOnOrAfter,
            created_at__lt: queryArg.createdAtBefore,
            created_at_lte: queryArg.createdAtOnOrBefore,
            created_at__isnull: queryArg.createdAtIsEmpty,

            client: queryArg.client,
            q: queryArg.q,
            page: queryArg.page,
            page_size: queryArg.pageSize,
            ordering: queryArg.sortBy
          }
        }),
        providesTags: ['give_plus']
      }),
      givePlusTerminalsRead: build.query<GivePlusTerminalsReadApiResponse, GivePlusTerminalsReadApiArg>({
        // query: queryArg => ({ url: `/give_plus/terminals/${queryArg.id}/` }),
        query: queryArg => ({ url: `/give_plus/terminals-details/${queryArg.id}/` }),
        providesTags: ['give_plus']
      }),
      givePlusTerminalsUpdate: build.mutation<GivePlusTerminalsUpdateApiResponse, GivePlusTerminalsUpdateApiArg>({
        query: queryArg => ({
          url: `/give_plus/terminals-details/${queryArg.id}/`,
          // url: `/give_plus/terminals/${queryArg.id}/`,
          method: 'PUT',
          body: queryArg.givePlusTerminal
        }),
        invalidatesTags: ['give_plus']
      }),
      givePlusTransactionsList: build.query<GivePlusTransactionsListApiResponse, GivePlusTransactionsListApiArg>({
        query: queryArg => ({
          url: `/give_plus/transactions/`,
          params: {
            date: queryArg.date,
            clients: queryArg.clients,
            terminals: queryArg.terminals,
            projects: queryArg.projects,
            q: queryArg.q,
            page: queryArg.page,
            page_size: queryArg.pageSize,
            ordering: queryArg.sortBy,

            terminal__name__icontains: queryArg.terminalIcontains,
            terminal__name__iexact: queryArg.terminalEquals,
            terminal__name__istartswith: queryArg.terminalStartsWith,
            terminal__name__iendswith: queryArg.terminalEndsWith,
            terminal__name__isnull: queryArg.terminalIsEmpty,
            terminal__name__in: queryArg.terminalIsAnyOf,

            donate: queryArg.donate,
            donate_ne: queryArg.donate__ne,
            donate__gt: queryArg.donate__gt,
            donate__gte: queryArg.donate__gte,
            donate__lt: queryArg.donate__lt,
            donate__lte: queryArg.donate__lte,
            donate__isnull: queryArg.donateIsEmpty,
            donate__in: queryArg.donateIsAnyOf,

            currency: queryArg.currency,

            terminal__config__app_type: queryArg.terminal__config__app_type,

            location__icontains: queryArg.locationIcontains,
            location__iexact: queryArg.locationEquals,
            location__istartswith: queryArg.locationStartsWith,
            location__iendswith: queryArg.locationEndsWith,
            location__isnull: queryArg.locationIsEmpty,
            location__in: queryArg.locationIsAnyOf,

            celebration__icontains: queryArg.celebrationIcontains,
            celebration__iexact: queryArg.celebrationEquals,
            celebration__istartswith: queryArg.celebrationStartsWith,
            celebration__iendswith: queryArg.celebrationEndsWith,
            celebration__isnull: queryArg.celebrationIsEmpty,
            celebration__in: queryArg.celebrationIsAnyOf,

            datetime__date: queryArg.datetimeIs,
            datetime_is_not: queryArg.datetimeNot,
            datetime__gt: queryArg.datetimeAfter,
            datetime_gte: queryArg.datetimeOnOrAfter,
            datetime__lt: queryArg.datetimeBefore,
            datetime_lte: queryArg.datetimeOnOrBefore,
            datetime__isnull: queryArg.datetimeIsEmpty
          }
        }),
        providesTags: ['give_plus']
      }),
      givePlusTransactionsGetChartsTransactionsStatistics: build.query<
        GivePlusTransactionsGetChartsTransactionsStatisticsApiResponse,
        GivePlusTransactionsGetChartsTransactionsStatisticsApiArg
      >({
        query: queryArg => ({
          url: `/give_plus/transactions/get_charts_transactions_statistics/`,
          params: {
            date: queryArg.date,
            clients: queryArg.clients,
            terminals: queryArg.terminals,
            projects: queryArg.projects,
            q: queryArg.q,
            page: queryArg.page,
            page_size: queryArg.pageSize
          }
        }),
        providesTags: ['give_plus']
      }),
      givePlusTransactionsGetChartsTransactionsStatisticsByTerminals: build.query<
        GivePlusTransactionsGetChartsTransactionsStatisticsByTerminalsApiResponse,
        GivePlusTransactionsGetChartsTransactionsStatisticsByTerminalsApiArg
      >({
        query: queryArg => ({
          url: `/give_plus/transactions/get_charts_transactions_statistics_by_terminals/`,
          params: {
            date: queryArg.date,
            clients: queryArg.clients,
            terminals: queryArg.terminals,
            projects: queryArg.projects,
            q: queryArg.q,
            page: queryArg.page,
            page_size: queryArg.pageSize,

            // date_after: queryArg.dateAfter,

            // date_before: queryArg.dateBefore,
            datetime__gt: queryArg.dateAfter,
            datetime_lte: queryArg.dateBefore,
            report_type: queryArg.reportType
          }
        }),
        providesTags: ['give_plus']
      }),
      givePlusTransactionsGetTransactionsStatistics: build.query<
        GivePlusTransactionsGetTransactionsStatisticsApiResponse,
        GivePlusTransactionsGetTransactionsStatisticsApiArg
      >({
        query: queryArg => ({
          url: `/give_plus/transactions/get_transactions_statistics/`,
          params: {
            date: queryArg.date,
            clients: queryArg.clients,
            terminals: queryArg.terminals,
            projects: queryArg.projects,
            q: queryArg.q,
            page: queryArg.page,
            page_size: queryArg.pageSize,
            date_before: queryArg.date_before,
            date_after: queryArg.date_after
          }
        }),
        providesTags: ['give_plus']
      }),
      givePlusTransactionsRead: build.query<GivePlusTransactionsReadApiResponse, GivePlusTransactionsReadApiArg>({
        query: queryArg => ({ url: `/give_plus/transactions/${queryArg.id}/` }),
        providesTags: ['give_plus']
      }),
      googleLoginCreate: build.mutation<GoogleLoginCreateApiResponse, GoogleLoginCreateApiArg>({
        query: queryArg => ({ url: `/google/login/`, method: 'POST', body: queryArg.google }),
        invalidatesTags: ['google']
      }),
      passwordResetCreate: build.mutation<PasswordResetCreateApiResponse, PasswordResetCreateApiArg>({
        query: queryArg => ({ url: `/password_reset/`, method: 'POST', body: queryArg.email }),
        invalidatesTags: ['password_reset']
      }),
      passwordResetConfirmCreate: build.mutation<
        PasswordResetConfirmCreateApiResponse,
        PasswordResetConfirmCreateApiArg
      >({
        query: queryArg => ({ url: `/password_reset/confirm/`, method: 'POST', body: queryArg.passwordToken }),
        invalidatesTags: ['password_reset']
      }),
      passwordResetValidateTokenCreate: build.mutation<
        PasswordResetValidateTokenCreateApiResponse,
        PasswordResetValidateTokenCreateApiArg
      >({
        query: queryArg => ({ url: `/password_reset/validate_token/`, method: 'POST', body: queryArg.resetToken }),
        invalidatesTags: ['password_reset']
      }),
      wefactClientRead: build.query<WefactClientsReadApiResponse, WefactClientsReadApiArg>({
        // query: queryArg => ({ url: `/core/clients/get_wefact_client_details?wefact_client_id=${queryArg.wefact_client_id}` }),
        query: queryArg => ({ url: `/core/client-details/get_wefact_client_details?wefact_client_id=${queryArg.wefact_client_id}` }),
        providesTags: ['core']
      }),
      wefactMDSClientUpdate: build.query<WefactMDSClientsUpdateApiResponse, WefactMDSClientsUpdateApiArg>({
        query: queryArg => ({ url: `/core/client-details/${queryArg.wefact_id}`, method: 'PUT', body: {client: queryArg.client_id}}),
        // core/client-details/780b06ec-982d-4396-8c96-547a5567bf00/
        providesTags: ['core']
      }),
    }),
    overrideExisting: false
  })
export { injectedRtkApi as coreApi }
export type CoreClientsListApiResponse = /** status 200  */ {
  count: number
  next?: string | null
  previous?: string | null
  results: ClientRead[]
}
export type CoreClientsListApiArg = {
  q?: string

  /** A page number within the paginated result set. */

  page?: number

  /** Number of results to return per page. */

  pageSize?: number

  sortBy?: string

  nameIcontains?: string
  nameEquals?: string
  nameStartsWith?: string
  nameEndsWith?: string
  nameIsEmpty?: string
  nameIsAnyOf?: string

  wefactClientIdIcontains?: string
  wefactClientIdEquals?: string
  wefactClientIdStartsWith?: string
  wefactClientIdEndsWith?: string
  wefactClientIdIsEmpty?: string
  wefactClientIdIsAnyOf?: string

  is_active?: string
}
export type CoreClientsCreateApiResponse = /** status 201  */ ClientRead
export type CoreClientsCreateApiArg = {
  client: Client
}
export type CoreClientsReadApiResponse = /** status 200  */ ClientRead

export type CoreClientsReadApiArg = {
  /** A UUID string identifying this client. */
  id: string
}
export type CoreClientsUpdateApiResponse = /** status 200  */ ClientRead
export type CoreClientsUpdateApiArg = {
  /** A UUID string identifying this client. */
  id: string
  client: Client
}
export type CoreClientsPartialUpdateApiResponse = /** status 200  */ ClientRead
export type CoreClientsPartialUpdateApiArg = {
  /** A UUID string identifying this client. */
  id: string
  client: Client
}
export type CoreClientsDeleteApiResponse = unknown
export type CoreClientsDeleteApiArg = {
  /** A UUID string identifying this client. */
  id: string
}
export type CoreUploadsListApiResponse = /** status 200  */ {
  count: number
  next?: string | null
  previous?: string | null
  results: ImageUploaderRead[]
}
export type CoreUploadsListApiArg = {
  /** Number of results to return per page. */
  limit?: number
  /** The initial index from which to return the results. */
  offset?: number
}
export type CoreUploadsCreateApiResponse = /** status 201  */ ImageUploaderRead
export type CoreUploadsCreateApiArg = {
  imageUploader: ImageUploader
}
export type CoreUploadsReadApiResponse = /** status 200  */ ImageUploaderRead
export type CoreUploadsReadApiArg = {
  /** A UUID string identifying this image uploader. */
  id: string
}
export type CoreUploadsUpdateApiResponse = /** status 200  */ ImageUploaderRead
export type CoreUploadsUpdateApiArg = {
  /** A UUID string identifying this image uploader. */
  id: string
  imageUploader: ImageUploader
}
export type CoreUploadsPartialUpdateApiResponse = /** status 200  */ ImageUploaderRead
export type CoreUploadsPartialUpdateApiArg = {
  /** A UUID string identifying this image uploader. */
  id: string
  imageUploader: ImageUploader
}
export type CoreUploadsDeleteApiResponse = unknown
export type CoreUploadsDeleteApiArg = {
  /** A UUID string identifying this image uploader. */
  id: string
}
export type CoreUsersListApiResponse = /** status 200  */ {
  count: number
  next?: string | null
  previous?: string | null
  results: UserRead[]
}
export type CoreUsersListApiArg = {
  client?: string
  q?: string

  /** A page number within the paginated result set. */

  page?: number

  /** Number of results to return per page. */

  pageSize?: number
  sortBy?: string

  usernameIcontains?: string
  usernameEquals?: string
  usernameStartsWith?: string
  usernameEndsWith?: string
  usernameIsEmpty?: string
  usernameIsAnyOf?: string

  firstNameIcontains?: string
  firstNameEquals?: string
  firstNameStartsWith?: string
  firstNameEndsWith?: string
  firstNameIsEmpty?: string
  firstNameIsAnyOf?: string

  lastNameIcontains?: string
  lastNameEquals?: string
  lastNameStartsWith?: string
  lastNameEndsWith?: string
  lastNameIsEmpty?: string
  lastNameIsAnyOf?: string

  emailIcontains?: string
  emailEquals?: string
  emailStartsWith?: string
  emailEndsWith?: string
  emailIsEmpty?: string
  emailIsAnyOf?: string

  clientIcontains?: string
  clientEquals?: string
  clientStartsWith?: string
  clientEndsWith?: string
  clientIsEmpty?: string
  clientIsAnyOf?: string

  is_active?: string

  is_staff?: string

  lastLoginIs?: string
  lastLoginNot?: string
  lastLoginAfter?: string
  lastLoginOnOrAfter?: string
  lastLoginBefore?: string
  lastLoginOnOrBefore?: string
  lastLoginIsEmpty?: string
}
export type CoreUsersCreateApiResponse = /** status 201  */ UserRead
export type CoreUsersCreateApiArg = {
  user: User
}
export type CoreUsersChangePasswordApiResponse = /** status 204 user serializer */ MaskUserResponse
export type CoreUsersChangePasswordApiArg = {
  changePassword: ChangePassword
}
export type CoreUsersGetAllPermissionsApiResponse = /** status 200  */ {
  count: number
  next?: string | null
  previous?: string | null
  results: UserRead[]
}
export type CoreUsersGetAllPermissionsApiArg = {
  email?: string
  emailIcontains?: string
  lastName?: string
  lastNameIcontains?: string
  firstName?: string
  firstNameIcontains?: string
  username?: string
  usernameIcontains?: string
  client?: string
  q?: string

  /** A page number within the paginated result set. */

  page?: number

  /** Number of results to return per page. */

  pageSize?: number
}
export type CoreUsersGetClientsApiResponse = /** status 200 client serializer */ ClientRead
export type CoreUsersGetClientsApiArg = {
  email?: string
  emailIcontains?: string
  lastName?: string
  lastNameIcontains?: string
  firstName?: string
  firstNameIcontains?: string
  username?: string
  usernameIcontains?: string
  client?: string
  q?: string

  /** A page number within the paginated result set. */

  page?: number

  /** Number of results to return per page. */

  pageSize?: number
}
export type CoreUsersGetUserInfoApiResponse = /** status 200 user serializer */ UserRead
export type CoreUsersGetUserInfoApiArg = {
  email?: string
  emailIcontains?: string
  lastName?: string
  lastNameIcontains?: string
  firstName?: string
  firstNameIcontains?: string
  username?: string
  usernameIcontains?: string
  client?: string
  q?: string

  /** A page number within the paginated result set. */

  page?: number

  /** Number of results to return per page. */

  pageSize?: number
}
export type CoreUsersMaskClientApiResponse = /** status 200 user serializer */ MaskUserResponse
export type CoreUsersMaskClientApiArg = {
  maskUserRequest: MaskUserRequest
}
export type CoreUsersReadApiResponse = /** status 200  */ UserRead
export type CoreUsersReadApiArg = {
  /** A UUID string identifying this user. */
  id: string
}
export type CoreUsersUpdateApiResponse = /** status 200  */ UserRead
export type CoreUsersUpdateApiArg = {
  /** A UUID string identifying this user. */
  id: string
  user: User
}
export type CoreUsersPartialUpdateApiResponse = /** status 200  */ UserRead
export type CoreUsersPartialUpdateApiArg = {
  /** A UUID string identifying this user. */
  id: string
  user: User
}
export type CoreUsersDeleteApiResponse = unknown
export type CoreUsersDeleteApiArg = {
  /** A UUID string identifying this user. */
  id: string
}
export type GiveTerminalsListApiResponse = /** status 200  */ {
  count: number
  next?: string | null
  previous?: string | null
  results: GiveTerminalRead[]
}
export type GiveTerminalsListApiArg = {
  client?: string
  q?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  pageSize?: number
  sortBy?: string

  serialNumberEquals?: string
  serialNumberIcontains?: string
  serialNumberStartsWith?: string
  serialNumberEndsWith?: string
  serialNumberIsEmpty?: string
  serialNumberIsAnyOf?: string
  serialNumber?: string

  nameIcontains?: string
  nameEquals?: string
  nameStartsWith?: string
  nameEndsWith?: string
  nameIsEmpty?: string
  nameIsAnyOf?: string
  nameContains?: string

  offline?: string

  is_enabled?: string
}
export type GiveTerminalsCreateApiResponse = /** status 201  */ GiveTerminalRead
export type GiveTerminalsCreateApiArg = {
  giveTerminal: GiveTerminal
}
export type GiveTerminalsReadApiResponse = /** status 200  */ GiveTerminalRead
export type GiveTerminalsReadApiArg = {
  /** A UUID string identifying this terminal. */
  id: string
}
export type GiveTerminalsUpdateApiResponse = /** status 200  */ GiveTerminalRead
export type GiveTerminalsUpdateApiArg = {
  /** A UUID string identifying this terminal. */
  id: string
  giveTerminal: GiveTerminal
}
export type GiveTransactionsListApiResponse = /** status 200  */ {
  count: number
  next?: string | null
  previous?: string | null
  results: GiveTransactionRead[]
}
export type GiveTransactionsListApiArg = {
  date?: string
  clients?: string
  terminals?: string
  reportType?: string
  client_id?: string
  q?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  pageSize?: number
  sortBy?: string

  terminalIcontains?: string
  terminalEquals?: string
  terminalStartsWith?: string
  terminalEndsWith?: string
  terminalIsEmpty?: string
  terminalIsAnyOf?: string

  creditCardBrandIcontains?: string
  creditCardBrandEquals?: string
  creditCardBrandStartsWith?: string
  creditCardBrandEndsWith?: string
  creditCardBrandIsEmpty?: string
  creditCardBrandIsAnyOf?: string

  clientIcontains?: string
  clientEquals?: string
  clientStartsWith?: string
  clientEndsWith?: string
  clientIsEmpty?: string
  clientIsAnyOf?: string

  donate?: string
  donate__ne?: string
  donate__gt?: string
  donate__gte?: string
  donate__lt?: string
  donate__lte?: string
  donateIsEmpty?: string
  donateIsAnyOf?: string

  datetimeIs?: string
  datetimeNot?: string
  datetimeAfter?: string
  datetimeOnOrAfter?: string
  datetimeBefore?: string
  datetimeOnOrBefore?: string
  datetimeIsEmpty?: string
}
export type GiveTransactionsGetChartsTransactionsStatisticsApiResponse =
  /** status 200 return statistics */ GiveTransactionStatistics
export type GiveTransactionsGetChartsTransactionsStatisticsApiArg = {
  date?: string
  clients?: string
  terminals?: string
  q?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  pageSize?: number
}
export type GiveTransactionsGetChartsTransactionsStatisticsByTerminalsApiResponse = /** status 200  */ {
  count: number
  next?: string | null
  previous?: string | null
  results?: GiveTransactionRead[]
}
export type GiveTransactionsGetChartsTransactionsStatisticsByTerminalsApiArg = {
  date?: string
  client_id?: string
  clients?: string
  terminals?: string
  q?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  pageSize?: number
  /** start date */
  dateAfter?: string
  /** end date */
  dateBefore?: string
  /** Report type */
  reportType?: string
}
export type GiveTransactionsGetTransactionsStatisticsApiResponse = /** status 200  */ {
  length: number
  count: number
  next?: string | null
  previous?: string | null
  results: GiveTransactionRead[]
}
export type GiveTransactionsGetTransactionsStatisticsApiArg = {
  date?: string
  clients?: string
  client_id?: string
  terminals?: string
  q?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  pageSize?: number
  /** start date */
  dateAfter?: string
  /** end date */
  dateBefore?: string
  /** Report type */
  reportType?: string
}
export type GiveTransactionsReadApiResponse = /** status 200  */ GiveTransactionRead
export type GiveTransactionsReadApiArg = {
  /** A UUID string identifying this transaction. */
  id: string
}
export type GivePlusPosTerminalsCreateApiResponse = /** status 201  */ PosTerminal
export type GivePlusPosTerminalsCreateApiArg = {
  posTerminal: PosTerminal
}
export type GivePlusPosTerminalsSendReceiptCreateApiResponse = /** status 201  */ PosTerminalReceipt
export type GivePlusPosTerminalsSendReceiptCreateApiArg = {
  posTerminalReceipt: PosTerminalReceipt
}
export type GivePlusPosTerminalsReadApiResponse = unknown
export type GivePlusPosTerminalsReadApiArg = {
  id: string
}
export type GivePlusPosTransactionsCreateApiResponse = /** status 201  */ GivePlusTransactionRead
export type GivePlusPosTransactionsCreateApiArg = {
  givePlusTransaction: GivePlusTransaction
}
export type GivePlusProjectsListApiResponse = /** status 200  */ ProjectRead[]
export type GivePlusProjectsListApiArg = {
  title?: string
  titleIcontains?: string
}
export type GivePlusProjectsReadApiResponse = /** status 200  */ ProjectRead
export type GivePlusProjectsReadApiArg = {
  /** A UUID string identifying this project. */
  id: string
}
export type GivePlusTerminalsListApiResponse = /** status 200  */ {
  count: number
  next?: string | null
  previous?: string | null
  results: GivePlusTerminalRead[]
}
export type GivePlusTerminalsListApiArg = {
  serialNumber?: string
  serialNumberIcontains?: string
  serialNumberStartsWith?: string
  serialNumberEndsWith?: string
  serialNumberIsEmpty?: string
  serialNumberIsAnyOf?: string
  serialNumberEquals?: string

  terminalSerialNumberIcontains?: string
  terminalSerialNumberEquals?: string
  terminalSerialNumberStartsWith?: string
  terminalSerialNumberEndsWith?: string
  terminalSerialNumberIsEmpty?: string
  terminalSerialNumberIsAnyOf?: string
  nameIsAnyOf?: string

  name?: string
  nameIcontains?: string
  nameEquals?: string
  nameStartsWith?: string
  nameEndsWith?: string
  nameIsEmpty?: string

  createdAtIs?: string
  createdAtNot?: string
  createdAtAfter?: string
  createdAtOnOrAfter?: string
  createdAtBefore?: string
  createdAtOnOrBefore?: string
  createdAtIsEmpty?: string

  client?: string
  q?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  pageSize?: number
  sortBy?: string
}
export type GivePlusTerminalsReadApiResponse = /** status 200  */ GivePlusTerminalRead
export type GivePlusTerminalsReadApiArg = {
  /** A UUID string identifying this terminal. */
  id: string
}
export type GivePlusTerminalsUpdateApiResponse = /** status 200  */ GivePlusTerminalRead
export type GivePlusTerminalsUpdateApiArg = {
  /** A UUID string identifying this terminal. */
  id: string
  givePlusTerminal: GivePlusTerminal
}
export type GivePlusTransactionsListApiResponse = /** status 200  */ {
  count: number
  next?: string | null
  previous?: string | null
  results: GivePlusTransactionRead[]
}
export type GivePlusTransactionsListApiArg = {
  date?: string
  clients?: string
  terminals?: string
  projects?: string
  q?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  pageSize?: number
  sortBy?: string

  terminalIcontains?: string
  terminalEquals?: string
  terminalStartsWith?: string
  terminalEndsWith?: string
  terminalIsEmpty?: string
  terminalIsAnyOf?: string

  donate?: string
  donate__ne?: string
  donate__gt?: string
  donate__gte?: string
  donate__lt?: string
  donate__lte?: string
  donateIsEmpty?: string
  donateIsAnyOf?: string

  currency?: string

  terminal__config__app_type?: string

  locationIcontains?: string
  locationEquals?: string
  locationStartsWith?: string
  locationEndsWith?: string
  locationIsEmpty?: string
  locationIsAnyOf?: string
  celebrationIcontains?: string
  celebrationEquals?: string
  celebrationStartsWith?: string
  celebrationEndsWith?: string
  celebrationIsEmpty?: string
  celebrationIsAnyOf?: string

  datetimeIs?: string
  datetimeNot?: string
  datetimeAfter?: string
  datetimeOnOrAfter?: string
  datetimeBefore?: string
  datetimeOnOrBefore?: string
  datetimeIsEmpty?: string
}
export type GivePlusTransactionsGetChartsTransactionsStatisticsApiResponse =
  /** status 200 return statistics */ GivePlusTransactionStatistics
export type GivePlusTransactionsGetChartsTransactionsStatisticsApiArg = {
  date?: string
  clients?: string
  terminals?: string
  projects?: string
  q?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  pageSize?: number
}
export type GivePlusTransactionsGetChartsTransactionsStatisticsByTerminalsApiResponse = /** status 200  */ {
  count: number
  next?: string | null
  previous?: string | null
  results: GivePlusTransactionRead[]
}
export type GivePlusTransactionsGetChartsTransactionsStatisticsByTerminalsApiArg = {
  date?: string
  clients?: string
  terminals?: string
  projects?: string
  q?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  pageSize?: number
  /** start date */
  dateAfter?: string
  /** end date */
  dateBefore?: string
  /** Report type */
  reportType?: string
}
export type GivePlusTransactionsGetTransactionsStatisticsApiResponse =
  /** status 200 return statistics */ GivePlusTransactionStatistics
export type GivePlusTransactionsGetTransactionsStatisticsApiArg = {
  date?: string
  clients?: string
  terminals?: string
  projects?: string
  q?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  pageSize?: number
  date_before?: string
  date_after?: string
}
export type GivePlusTransactionsReadApiResponse = /** status 200  */ GivePlusTransactionRead
export type GivePlusTransactionsReadApiArg = {
  /** A UUID string identifying this transaction. */
  id: string
}
export type GoogleLoginCreateApiResponse = /** status 201  */ Google
export type GoogleLoginCreateApiArg = {
  google: Google
}
export type PasswordResetCreateApiResponse = /** status 201  */ Email
export type PasswordResetCreateApiArg = {
  email: Email
}
export type PasswordResetConfirmCreateApiResponse = /** status 201  */ PasswordToken
export type PasswordResetConfirmCreateApiArg = {
  passwordToken: PasswordToken
}
export type PasswordResetValidateTokenCreateApiResponse = /** status 201  */ ResetToken
export type PasswordResetValidateTokenCreateApiArg = {
  resetToken: ResetToken
}
export type Client = {
  is_deleted?: boolean
  name: string
  is_active?: boolean
  join_date?: string
  active_apps?: (1 | 2)[]
}
export type ClientRead = {
  id?: string
  is_deleted?: boolean
  created_at?: string
  updated_at?: string
  name: string
  is_active?: boolean
  join_date?: string
  active_apps?: (1 | 2)[]
}
export type WefactClientRead = {
  client_name?: string
  id?: string
}
export type WefactMDSClientUpdate = any
export type ImageUploader = {
  title?: string | null
  description?: string
  image_url: string
}
export type ImageUploaderRead = {
  id?: string
  uploader?: string
  uploader_id?: string
  image_absolute_url?: string
  title?: string | null
  description?: string
  image_url: string
}
export type User = {
  client?: Client
  client_id: string
  password: string
  last_login?: string | null
  is_superuser?: boolean
  username: string
  first_name?: string
  last_name?: string
  email?: string
  is_staff?: boolean
  is_active?: boolean
  date_joined?: string
  is_deleted?: boolean
  is_viewer?: boolean
  groups?: number[]
  user_permissions?: number[]
  give_terminals?: string[]
  give_plus_terminals?: string[]
  sendEmail?: boolean
}
export type UserRead = {
  id?: string
  client?: ClientRead
  client_id: string
  password: string
  last_login?: string | null
  is_superuser?: boolean
  username: string
  first_name?: string
  last_name?: string
  email?: string
  is_staff?: boolean
  is_active?: boolean
  date_joined?: string
  is_deleted?: boolean
  created_at?: string
  updated_at?: string
  groups?: number[]
  user_permissions?: number[]
}
export type MaskUserResponse = {
  access_token: string
  expires_in: number
  token_type: string
  scope: string
  refresh_token: string
}
export type ChangePassword = {
  user: string
  old_password?: string
  new_password: string
  sendEmail?: boolean
}
export type MaskUserRequest = {
  client_id: string
  auth_id: string
}
export type GiveTerminal = {
  client: Client
  is_deleted?: boolean
  name?: string | null
  CEF_id?: string | null
  reason_of_donation?: string | null
  serial_number: string
  payment_provider?: 0 | 1
  offline?: boolean
  is_enabled?: boolean
  sepay_serial_number?: string | null
}
export type GiveTerminalRead = {
  id?: string
  client: ClientRead
  is_deleted?: boolean
  created_at?: string
  updated_at?: string
  name?: string | null
  serial_number: string
  payment_provider?: 0 | 1
  offline?: boolean
  is_enabled?: boolean
  sepay_serial_number?: string | null
}
export type GiveTransaction = {
  terminal: GiveTerminal
  client: Client
  is_deleted?: boolean
  donate?: string
  currency?: number
  status?: 0 | 1 | 2
  provider_id?: string | null
  credit_card_brand?: string | null
}
export type GiveTransactionRead = {
  id?: string
  terminal: GiveTerminalRead
  client: ClientRead
  is_deleted?: boolean
  created_at?: string
  updated_at?: string
  donate?: string
  currency?: number
  status?: 0 | 1 | 2
  datetime?: string
  provider_id?: string | null
  credit_card_brand?: string | null
}
export type GiveTransactionStatistics = {
  count: number
  total: number
  average: number
}
export type PosTerminal = {
  device_id: string
}
export type PosTerminalReceipt = {
  email?: string | null
  phone?: string | null
  transaction_id: string
}
export type GivePlusTransaction = {
  id: string
  secret_key: string
  datetime: string
  is_deleted?: boolean
  donate?: string
  currency?: number
  status?: 0 | 1 | 2
  provider_id?: string | null
  credit_card_brand?: string | null
  client?: string | null
  terminal: string
  project?: string | null
}
export type GivePlusTransactionRead = {
  id: string
  secret_key: string
  datetime: string
  is_deleted?: boolean
  created_at?: string
  updated_at?: string
  donate?: string
  currency?: number
  status?: 0 | 1 | 2
  provider_id?: string | null
  credit_card_brand?: string | null
  client?: string | null
  terminal: string
  project?: string | null
}
export type Project = {
  is_deleted?: boolean
  title: string
  options?: number[]
  image?: string | null
  client?: string | null
}
export type ProjectRead = {
  id?: string
  is_deleted?: boolean
  created_at?: string
  updated_at?: string
  title: string
  options?: number[]
  image?: string | null
  client?: string | null
}
export type TerminalConfig = {
  projects: Project[]
  is_deleted?: boolean
  isUsedWithinMass?: boolean
  event_name?: string
  event_date?: string | null
  app_type?: 1 | 2 | 3
  company_logo?: string | null
  default_language?: string
  currency?: number
  available_languages?: string[]
  options?: number[]
  receipt_available?: boolean
  client?: string | null
}
export type TerminalConfigRead = {
  id?: string
  projects: ProjectRead[]
  is_deleted?: boolean
  created_at?: string
  updated_at?: string
  app_type?: 1 | 2 | 3
  company_logo?: string | null
  default_language?: string
  currency?: number
  available_languages?: string[]
  options?: number[]
  receipt_available?: boolean
  client?: string | null
}
export type GivePlusTerminal = {
  config: TerminalConfig
  client: Client
  is_deleted?: boolean
  name: string
  serial_number: string
  terminal_serial_number: string
  device?: 0 | 1
  is_offline?: boolean
  is_enabled?: boolean
  last_online_time: string
}
export type GivePlusTerminalRead = {
  id?: string
  config: TerminalConfigRead
  client: ClientRead
  is_deleted?: boolean
  created_at?: string
  updated_at?: string
  name: string
  serial_number: string
  terminal_serial_number: string
  device?: 0 | 1
  is_offline?: boolean
  is_enabled?: boolean
  last_online_time: string
}
export type GivePlusTransactionStatistics = {
  count: number
  total: number
  average: number
}
export type Google = {
  access_token?: string
  error?: string
  client_id: string
}
export type Email = {
  email: string
}
export type PasswordToken = {
  password: string
  token: string
}
export type ResetToken = {
  token: string
}
export type WefactClientsReadApiResponse = /** status 200  */ WefactClientRead
export type WefactClientsReadApiArg = {
  wefact_client_id: string
}
export type WefactMDSClientsUpdateApiResponse = /** status 200  */ WefactMDSClientUpdate
export type WefactMDSClientsUpdateApiArg = {
  client_id?: string,
  wefact_id?: string
}
export const {
  useCoreClientsListQuery,
  useLazyCoreClientsListQuery,
  useCoreClientsCreateMutation,
  useCoreClientsReadQuery,
  useLazyCoreClientsReadQuery,
  useCoreClientsUpdateMutation,
  useCoreClientsPartialUpdateMutation,
  useCoreClientsDeleteMutation,
  useCoreUploadsListQuery,
  useLazyCoreUploadsListQuery,
  useCoreUploadsCreateMutation,
  useCoreUploadsReadQuery,
  useLazyCoreUploadsReadQuery,
  useCoreUploadsUpdateMutation,
  useCoreUploadsPartialUpdateMutation,
  useCoreUploadsDeleteMutation,
  useCoreUsersListQuery,
  useLazyCoreUsersListQuery,
  useCoreUsersCreateMutation,
  useCoreUsersChangePasswordMutation,
  useCoreUsersGetAllPermissionsQuery,
  useLazyCoreUsersGetAllPermissionsQuery,
  useCoreUsersGetClientsQuery,
  useLazyCoreUsersGetClientsQuery,
  useCoreUsersGetUserInfoQuery,
  useLazyCoreUsersGetUserInfoQuery,
  useCoreUsersMaskClientMutation,
  useCoreUsersReadQuery,
  useLazyCoreUsersReadQuery,
  useCoreUsersUpdateMutation,
  useCoreUsersPartialUpdateMutation,
  useCoreUsersDeleteMutation,
  useGiveTerminalsListQuery,
  useLazyGiveTerminalsListQuery,
  useGiveTerminalsCreateMutation,
  useGiveTerminalsReadQuery,
  useLazyGiveTerminalsReadQuery,
  useGiveTerminalsUpdateMutation,
  useGiveTransactionsListQuery,
  useLazyGiveTransactionsListQuery,
  useGiveTransactionsGetChartsTransactionsStatisticsQuery,
  useLazyGiveTransactionsGetChartsTransactionsStatisticsQuery,
  useGiveTransactionsGetChartsTransactionsStatisticsByTerminalsQuery,
  useLazyGiveTransactionsGetChartsTransactionsStatisticsByTerminalsQuery,
  useGiveTransactionsGetTransactionsStatisticsQuery,
  useLazyGiveTransactionsGetTransactionsStatisticsQuery,
  useGiveTransactionsReadQuery,
  useLazyGiveTransactionsReadQuery,
  useGivePlusPosTerminalsCreateMutation,
  useGivePlusPosTerminalsSendReceiptCreateMutation,
  useGivePlusPosTerminalsReadQuery,
  useLazyGivePlusPosTerminalsReadQuery,
  useGivePlusPosTransactionsCreateMutation,
  useGivePlusProjectsListQuery,
  useLazyGivePlusProjectsListQuery,
  useGivePlusProjectsReadQuery,
  useLazyGivePlusProjectsReadQuery,
  useGivePlusTerminalsListQuery,
  useLazyGivePlusTerminalsListQuery,
  useGivePlusTerminalsReadQuery,
  useLazyGivePlusTerminalsReadQuery,
  useGivePlusTerminalsUpdateMutation,
  useGivePlusTransactionsListQuery,
  useLazyGivePlusTransactionsListQuery,
  useGivePlusTransactionsGetChartsTransactionsStatisticsQuery,
  useLazyGivePlusTransactionsGetChartsTransactionsStatisticsQuery,
  useGivePlusTransactionsGetChartsTransactionsStatisticsByTerminalsQuery,
  useLazyGivePlusTransactionsGetChartsTransactionsStatisticsByTerminalsQuery,
  useGivePlusTransactionsGetTransactionsStatisticsQuery,
  useLazyGivePlusTransactionsGetTransactionsStatisticsQuery,
  useGivePlusTransactionsReadQuery,
  useLazyGivePlusTransactionsReadQuery,
  useGoogleLoginCreateMutation,
  usePasswordResetCreateMutation,
  usePasswordResetConfirmCreateMutation,
  usePasswordResetValidateTokenCreateMutation,
  useLazyWefactClientReadQuery,
  useLazyWefactMDSClientUpdateQuery
} = injectedRtkApi
